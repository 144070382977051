import { API_ORIGIN } from '@afishauz/api/config';
import { Link } from '@afishauz/core/widgets/link';
import { Afishauz } from '@afishauz/icons';
import { useTelegramAuth } from '@afishauz/lib/react/use-telegram-auth';
import { Button } from '@afishauz/ui-kit/button';
import { Dialog, DialogTitle } from '@afishauz/ui-kit/dialog';
import { DialogContent } from '@afishauz/ui-kit/dialog';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';
import toast from 'react-hot-toast';

export const AuthModal = ({
  show = false,
  onClose = () => {},
}: {
  show?: boolean;
  onClose?: () => void;
}) => {
  const t = useTranslations('common');
  const telegramAuth = useTelegramAuth();

  const handleTelegramAuth = async () => {
    try {
      const user = await telegramAuth({
        botId: Number(process.env.NEXT_PUBLIC_TELEGRAM_AUTH_BOT_ID ?? 0),
        requestAccess: 'write',
      });
      const url = new URL('/connect/telegram/check', API_ORIGIN);
      const searchParams = new URLSearchParams();
      for (const [name, value] of Object.entries(user)) {
        if (value) {
          searchParams.set(name, value.toString());
        }
      }
      url.search = searchParams.toString();
      window.location.href = url.toString();
    } catch (error) {
      toast.error(t('layouts.auth_error'));
    }
  };

  return (
    <Dialog
      open={show}
      onOpenChange={open => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent>
        <section className='flex flex-col items-center gap-y-8'>
          <DialogTitle>{t('layouts.auth')}</DialogTitle>
          <div className='flex flex-col items-center gap-y-4'>
            <Button
              component={Link}
              nativeLink
              href='/connect/google'
              className='w-72 flex items-center'
            >
              <span className='relative w-full flex justify-center items-center'>
                <span className='absolute left-0 top-0 flex justify-center items-center h-full text-gray-500 text-xl'>
                  <FontAwesomeIcon icon={faGoogle} />
                </span>
                Google
              </span>
            </Button>
            <Button
              component={Link}
              nativeLink
              href='/connect/facebook'
              className='w-72 flex items-center'
            >
              <span className='relative w-full flex justify-center items-center'>
                <span className='absolute left-0 top-0 flex justify-center items-center h-full text-gray-500 text-xl'>
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className='text-gray-500 text-xl mr-3'
                  />
                </span>
                Facebook
              </span>
            </Button>
            <Button
              onClick={handleTelegramAuth}
              className='w-72 flex items-center'
            >
              <span className='relative w-full flex justify-center items-center'>
                <span className='absolute left-0 top-0 flex justify-center items-center h-full text-gray-500 text-xl'>
                  <FontAwesomeIcon
                    icon={faTelegram}
                    className='text-gray-500 text-xl mr-3'
                  />
                </span>
                Telegram
              </span>
            </Button>
          </div>
          <Afishauz height={18} className='text-afisha' />
        </section>
      </DialogContent>
    </Dialog>
  );
};
