import { useEffect } from 'react';

interface TelegramUser {
  id: number;
  auth_date: number;
  hash: string;
  first_name?: string;
  last_name?: string;
  username?: string;
}

type WindowWithTelegramLoginWidget = Window & {
  Telegram?: {
    Login?: {
      auth: (
        params: {
          bot_id: number;
          request_access: 'read' | 'write' | undefined;
          lang?: string;
        },
        cb: (user: TelegramUser | false) => void,
      ) => void;
    };
  };
};

export function useTelegramAuth(version = 22) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://telegram.org/js/telegram-widget.js?${version}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [version]);

  return ({
    botId,
    requestAccess,
    lang,
  }: {
    botId: number;
    requestAccess?: 'read' | 'write';
    lang?: string;
  }) => {
    const auth = (window as WindowWithTelegramLoginWidget).Telegram?.Login
      ?.auth;

    if (!auth) {
      throw new Error('Telegram widget script has not been loaded');
    }

    return new Promise<TelegramUser>((resolve, reject) => {
      try {
        auth({ bot_id: botId, request_access: requestAccess, lang }, user => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error('Failed to authorize with Telegram'));
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  };
}
